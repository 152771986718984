<template>
    <div>
        <b-container>
            <section class="admin">
                <div class="sector">
                    <AdminNavigation />
                    <h2 class="main-title">Manage events</h2>
                    <div class="clearfix">
                        <router-link to="/add-admin-event" class="details-button details-button--red float-right"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Add event</router-link>
                    </div>
                    <div class="admin__box">
                        <div class="admin__table">
                            <div class="input-box">
                                <div v-if="filterEvent" @click.prevent="clearFilter" class="clear-icon clear-icon--filter">
                                    <simple-line-icons icon="close" size="small" color="#757575" />
                                </div>
                                <input v-model="filterEvent" class="custom-search-bar" type="text" placeholder="Filter" />
                            </div>
                            <b-table
                                hover
                                id="pendingEvents"
                                :items="getSortedAdminEventsList"
                                :fields="fields"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :per-page="perPage"
                                :current-page="Number(currentPage)"
                                :filter="filterEvent"
                                responsive>
                                <template slot="status" slot-scope="data">
                                    <span class="bg-success text-light p-1" v-if="data.item.status == 'Verified'">Verified</span>
                                    <span class="bg-warning text-light p-1" v-else-if="data.item.status == 'Pending'">Pending</span>
                                    <span class="bg-danger text-light p-1" v-else-if="data.item.status == 'Denied'">Denied</span>
                                    <p v-if="data.item.status == 'Denied' && data.item.reason" class="mt-2">{{ data.item.reason }}</p>
                                </template>
                                <template slot="manage" slot-scope="data">
                                    <span>
                                        <div>
                                            <router-link :to="{ name: 'admin-event', params: { id: data.item.id }}" class="details-button details-button--default mt-0 mr-1"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Preview</router-link>
                                            <router-link :to="{ name: 'edit-admin-event', params: { id: data.item.id }}" class="details-button details-button--default mt-1 mr-1"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Edit</router-link>
                                            <button @click.prevent="deleteAdminEvent(data.item.id, data.item.image, data.item.uid)" :disabled="getDisabledAdminEvent" :class="getDisabledAdminEvent ? 'disabled-element' : ''" class="details-button details-button--dark-red mt-1 mr-1 mb-0"><simple-line-icons icon="close" size="small" color="#fff" />Delete</button>
                                        </div>
                                        <hr>
                                        <div>
                                            <button @click.prevent="verifyEvent(data.item.id)" class="details-button details-button--green mt-1 mr-1 mb-0"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Verify</button>
                                            <button @click.prevent="setPendingEvent(data.item.id)" class="details-button details-button--yellow mt-1 mr-1 mb-0"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Set pending</button>
                                            <button @click.prevent="denyEvent(data.item.id)" class="details-button details-button--dark-red mt-1 mr-1 mb-0"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Deny</button>
                                        </div>
                                        <hr>
                                        <div>
                                            <button @click.prevent="upgradeEvent(data.item.id)" class="details-button details-button--blue mt-1 mr-1 mb-0"><simple-line-icons icon="arrowUp" size="small" color="#504e70" />Upgrade</button>
                                            <button @click.prevent="downgradeEvent(data.item.id)" class="details-button details-button--black mt-1 mr-1 mb-0"><simple-line-icons icon="arrowDown" size="small" color="#504e70" />Downgrade</button>
                                        </div>
                                    </span>
                                </template>
                            </b-table>
                            <b-pagination
                                @change="scrollToTop('pendingEvents');"
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="perPage"
                                aria-controls="pendingEvents"
                            ></b-pagination>
                            <label class="mr-2" for="paginationInput">Go to page:</label>
                            <b-form-input class="pagination__input" id="paginationInput" v-model="currentPage"></b-form-input>
                        </div>
                    </div>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import AdminNavigation from '@/components/AdminNavigation'
import { mapGetters } from 'vuex'
import MoveTo from 'moveto'
export default {
  components: {
    SimpleLineIcons,
    AdminNavigation
  },
  data: function () {
    return {
      perPage: 5,
      currentPage: '1',
      sortBy: 'id',
      sortDesc: true,
      filterEvent: '',
      fields: [
        { key: 'id', label: 'Event Id', sortable: true },
        { key: 'uid', label: 'User', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'sponsor', label: 'Sponsor', sortable: true },
        { key: 'manage', label: 'Manage', sortable: false }
      ]
    }
  },
  methods: {
    clearFilter () {
      this.filterEvent = ''
    },
    verifyEvent (id) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: 'Are you sure you want to verify this event?',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#13b81b',
        cancelButtonColor: '#f1352e',
        focusCancel: false
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('verifyEvent', { id })
        }
      })
    },
    setPendingEvent (id) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: 'Are you sure you want set pending to this event?',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#13b81b',
        cancelButtonColor: '#f1352e',
        focusCancel: false
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('setPendingEvent', { id })
        }
      })
    },
    denyEvent (id) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: 'Are you sure you want to deny this event?',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#13b81b',
        cancelButtonColor: '#f1352e',
        focusCancel: false,
        input: 'text',
        inputPlaceholder: 'Enter reason for the refusal'
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('denyEvent', { id, reason: result.value })
        }
      })
    },
    upgradeEvent (id) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: 'Are you sure you want to upgrade this event?',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#13b81b',
        cancelButtonColor: '#f1352e',
        focusCancel: false
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('upgradeEvent', { id })
        }
      })
    },
    downgradeEvent (id) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: 'Are you sure you want to downgrade this event?',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#13b81b',
        cancelButtonColor: '#f1352e',
        focusCancel: false
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('downgradeEvent', { id })
        }
      })
    },
    deleteAdminEvent (id, imageName, userId) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: 'Are you sure you want to delete this event?',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#f1352e',
        cancelButtonColor: '#13b81b',
        focusCancel: true
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('setDisabledAdminEvent')
          this.$store.dispatch('deleteAdminEvent', { id: id, image: imageName, userId: userId })
            .then(() => {

            })
            .catch(error => {
              this.$store.dispatch('clearDisabledAdminEvent')
            })
        }
      })
    },
    scrollToTop (element) {
      const moveTo = new MoveTo({
        tolerance: 75,
        duration: 1000
      })

      const target = document.getElementById(element)

      moveTo.move(target)
    }
  },
  computed: {
    ...mapGetters([
      'getAdminEventPending',
      'getAdminEventsList',
      'getDisabledAdminEvent'
    ]),
    getSortedAdminEventsList () {
      var sorted = this.getAdminEventsList.map(function (event) {
        return {
          id: event.id,
          uid: event.user,
          status: (event.status == 1) ? 'Verified' : (event.status == 2) ? 'Pending' : (event.status == 3) ? 'Denied' : '',
          sponsor: event.ribbon,
          reason: event.reason,
          image: event.image
        }
      })

      return sorted
    },
    rows () {
      return this.getSortedAdminEventsList.length
    }
  },
  beforeCreate () {
    this.$store.dispatch('getAdminEvents')
    this.$store.dispatch('clearDisabledAdminEvent')
  }
}
</script>
